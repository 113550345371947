<template>
  <el-dialog
    title="编辑图片"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="1000px"
    v-loading="loading"
  >
    <div class="flex">
      <div>
        <div style="width: 480px; height: 480px">
          <vueCropper
            ref="cropper"
            @realTime="realTime"
            :img="option.img"
            :autoCrop="option.autoCrop"
            :fixed="fixed"
            :fixedNumber="fixedNumber"
            :canScale="canScale"
            :centerBox="true"
            fillColor="#ffffff"
            autoCropWidth="480"
            autoCropHeight="480"
          />
        </div>
        <div class="flex jc-e m-t20">
          <el-button type="primary" icon="el-icon-crop" @click="openCrop"
            >裁剪</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="rotateRight90"
            >顺时针旋转</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-left"
            @click="rotateLeft90"
            >逆时针旋转</el-button
          >
        </div>
      </div>
      <div class="m-l20" style="width: 480px">
        <div class="preview">
          <span class="title">实时预览图</span>
          <el-button type="success" @click="finish">完成编辑</el-button>
          <el-button class="m-l20" type="info" @click="show = false"
            >取消编辑</el-button
          >
        </div>

        <div :style="previewStyle">
          <div :style="previews.div">
            <img :src="previews.url" :style="previews.img" />
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: { VueCropper },
  data() {
    return {
      loading: false,
      show: false,
      previews: {},
      previewStyle: {},
      option: {
        img: "", // 裁剪图片的地址
        autoCrop: true,
      },
      canScale: false,
      fixed: false,
      fixedNumber: [1, 1],
      qiniu: {
        token: "",
        key: "",
      },
      upload_qiniu_addr: "https://img.curiousmore.com/",
    };
  },
  methods: {
    open(img, opt) {
      if (!img) {
        this.$message("请检查图片路径");
        return;
      }
      if (opt) {
        if (opt.fixedNumber) {
          this.fixed = true;
          this.fixedNumber = opt.fixedNumber;
        }
      } else {
        this.fixed = false;
      }
      if (img) {
        this.option.img = img;
        this.show = true;
      }
    },
    async uploadImg(data, fileName) {
         this.loading = true;
      await this.getToken();
      // 上传到服务器
     
      let formData = new FormData();
      formData.append("file", data);
      formData.append("token", this.qiniu.token);
      formData.append("key", this.qiniu.key);
      this.$axios({
        method: "post",
        url: "https://up-z0.qiniup.com",
        data: formData,
      })
        .then((res) => {
          let path = this.upload_qiniu_addr + this.qiniu.key;
          this.$emit("success", path);
          this.loading = false;
          this.show = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("保存失败");
        });
    },

    getToken() {
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let data = {
          key: timestamp + ".png",
          type: "pic",
        };
        this.$axios({
          method: "post",
          url: "https://www.curiousmore.com/qmore/push/upload/getToken",
          data: data,
        })
          .then((res) => {
            this.qiniu.token = res.data.data;
            this.qiniu.key = data.key;
            resolve(res);
          })
          .catch((err) => {
             this.loading = false;
            this.$message.error("保存失败");
            reject(err);
          });
      });
    },
    finish() {
      let fileName = "裁剪图";
      this.$confirm(
        "确定将原图片替换为编辑后的图片，图片替换后无法撤回。"
      ).then((res) => {
        this.$refs.cropper.getCropBlob((data) => {
          //获取截图的 blob 数据
          // 上传图片
          this.uploadImg(data, fileName);
        });
      });
    },
    openCrop() {
      if (this.option.autoCrop) {
        this.$refs.cropper.clearCrop();
      }
      this.option.autoCrop = !this.option.autoCrop;
    },
    rotateRight90() {
      this.$refs.cropper.rotateRight();
    },
    rotateLeft90() {
      this.$refs.cropper.rotateLeft();
    },

    // 实时预览
    realTime(data) {
      if (data) {
        this.previewStyle = {
          width: data.w + "px",
          height: data.h + "px",
          overflow: "hidden",
          margin: "0",
        };
        this.previews = data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.preview {
  padding-left: 30px;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  margin-right: 20px;
}
</style>